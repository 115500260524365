const nivos = (sliderSelector, interval, catchSelector) => {
  // 初期設定
  const slider = document.querySelector(sliderSelector);
  const sliderChildren = slider.children;
  let activeIndex = 1;
  const splitX = 8;
  const splitY = 5;
  const pattern = {
    0: {
      scaleY: [1, 0],
      easing: "easeOutExpo",
      endDelay: interval,
      delay: anime.stagger(50, {
        grid: [splitX, splitY],
        from: "center",
        easing: "linear",
      }),
    },
    1: {
      scale: [1, 0],
      rotate: [0, 360],
      easing: "easeOutExpo",
      endDelay: interval,
      delay: anime.stagger(100, {
        grid: [splitX, splitY],
        from: "center",
        easing: "linear",
      }),
    },
    2: {
      scaleY: [1, 0],
      easing: "easeOutExpo",
      endDelay: interval,
      delay: anime.stagger(50, {
        grid: [splitX, splitY],
        from: "first",
        axis: "y",
        easing: "linear",
      }),
    },
    3: {
      scaleX: [1, 0],
      easing: "easeOutExpo",
      endDelay: interval,
      delay: anime.stagger(50, {
        grid: [splitX, splitY],
        from: "first",
        axis: "x",
        easing: "linear",
      }),
    },
  };
  let catchIndex = null;
  let catchWapper = null;
  let catches = null;
  let catchAnime = null;

  // 関数宣言
  const createDiv = () => {
    [...sliderChildren].forEach((element, index) => {
      let el = document.createElement("div");
      anime.set(element, {
        display: "flex",
        flexWrap: "wrap",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: index * -1 + [...sliderChildren].length,
      });
      for (let index = 0; index < splitX * splitY; index++) {
        element.appendChild(el.cloneNode(true));
      }
    });
  };
  const setBackground = (sliderW, sliderH) => {
    [...sliderChildren].forEach((element) => {
      const img = element.querySelector("img");
      const divs = element.querySelectorAll("div");
      const imgW = img.naturalWidth;
      const imgH = img.naturalHeight;
      const imgSrc = img.getAttribute("src");
      let bgs = "";
      let fixX = "";
      let fixY = "";
      if (sliderW / sliderH < imgW / imgH) {
        bgs = `auto ${sliderH}px`;
        fixX = ((sliderH / imgH) * imgW - sliderW) / 2;
      } else {
        bgs = `${sliderW}px auto`;
        fixY = ((sliderW / imgW) * imgH - sliderH) / 2;
      }
      for (let i = 0; i < divs.length; i++) {
        const bgpX = (i % splitX) / splitX;
        const bgpY = Math.floor(i / splitX) / splitY;
        anime.set(divs[i], {
          width: `${100 / splitX}%`,
          height: `${100 / splitY}%`,
          backgroundImage: `url('${imgSrc}')`,
          backgroundPosition: `${-1 * bgpX * sliderW - fixX}px ${
            -1 * bgpY * sliderH - fixY
          }px`,
          backgroundSize: bgs,
        });
      }
    });
  };
  const init = () => {
    anime.set(slider, {
      height: "100%",
      overflow: "hidden",
      position: "relative",
      width: "100%",
    });
    slider.querySelectorAll("img").forEach((el) => {
      el.style.display = "none";
    });
    createDiv();
    setBackground(slider.clientWidth, slider.clientHeight);
    activeIndex++;
  };
  const initCatch = () => {
    catchWapper = document.querySelector(catchSelector);
    catches = catchWapper.querySelectorAll(":scope > img");
    catchIndex = 1;
    catchAnime = {
      opacity: [0, 1],
      translateX: ["10%", 0],
      duration: 2000,
      easing: "easeOutExpo",
    };
    anime.set(catchWapper, {
      height: anime.get(catches[0], "height"),
    });
    catches.forEach((catche) => {
      anime.set(catche, {
        left: 0,
        opacity: 0,
        position: "absolute",
        top: 0,
      });
    });
    catchAnime.targets = catches[0];
    anime(catchAnime);
    catchIndex++;
  };
  const catchTransition = () => {
    if (catchIndex > catches.length) {
      catchIndex = 1;
    }
    anime.set(catches, {
      opacity: 0,
    });
    catchAnime["targets"] = catches[catchIndex - 1];
    anime(catchAnime);
    catchIndex++;
  };
  const animation = {
    clearId: null,
    start() {
      this.clearId = setInterval(this.update, interval);
    },
    stop() {
      clearInterval(this.clearId);
    },
    update() {
      let hiddenTarget = null;
      if (activeIndex > [...sliderChildren].length) {
        activeIndex = 1;
        hiddenTarget = slider.querySelector(
          `:scope > div:nth-of-type(${[...sliderChildren].length})`
        );
      } else {
        hiddenTarget = slider.querySelector(
          `:scope > div:nth-of-type(${activeIndex - 1})`
        );
      }
      const innerDivs = hiddenTarget.querySelectorAll(":scope > div");
      const patternNum = hiddenTarget.getAttribute("data-nivos");
      const options = Object.assign(pattern[patternNum], {
        targets: innerDivs,
        begin() {
          if (catchSelector) catchTransition();
        },
        complete() {
          for (let i = 0; i < innerDivs.length; i++) {
            innerDivs[i].style.transform = "";
            innerDivs[i].style.opacity = 1;
          }
          const zindex = [...sliderChildren].map((el) => el.style.zIndex);
          zindex.unshift(zindex.pop());
          [...sliderChildren].forEach((el, index) => {
            el.style.zIndex = zindex[index];
          });
        },
      });
      anime(options);
      activeIndex++;
    },
  };

  // 処理開始
  window.addEventListener("load", () => {
    init();
    if (catchSelector) {
      initCatch();
    }
    animation.start();
  });
  window.addEventListener("resize", () => {
    setBackground(slider.clientWidth, slider.clientHeight);
  });
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      animation.stop();
    } else {
      animation.start();
    }
  });
};
